import { Colors, FigureData, Figures } from "../../../helpers/types";

export const initialFigures: { [key: string]: FigureData } = {
  "black-rook-1-8": {
    id: "black-rook-1-8",
    name: Figures.ROOK,
    x: 1,
    y: 8,
    color: Colors.BLACK,
  },
  "black-knight-2-8": {
    id: "black-knight-2-8",
    name: Figures.KNIGHT,
    x: 2,
    y: 8,
    color: Colors.BLACK,
  },
  "black-bishop-3-8": {
    id: "black-bishop-3-8",
    name: Figures.BISHOP,
    x: 3,
    y: 8,
    color: Colors.BLACK,
  },
  "black-queen-4-8": {
    id: "black-queen-4-8",
    name: Figures.QUEEN,
    x: 4,
    y: 8,
    color: Colors.BLACK,
  },
  "black-king-5-8": {
    id: "black-king-5-8",
    name: Figures.KING,
    x: 5,
    y: 8,
    color: Colors.BLACK,
  },
  "black-bishop-6-8": {
    id: "black-bishop-6-8",
    name: Figures.BISHOP,
    x: 6,
    y: 8,
    color: Colors.BLACK,
  },
  "black-knight-7-8": {
    id: "black-knight-7-8",
    name: Figures.KNIGHT,
    x: 7,
    y: 8,
    color: Colors.BLACK,
  },
  "black-rook-8-8": {
    id: "black-rook-8-8",
    name: Figures.ROOK,
    x: 8,
    y: 8,
    color: Colors.BLACK,
  },

  "black-pawn-1-7": {
    id: "black-pawn-1-7",
    name: Figures.PAWN,
    y: 7,
    x: 1,
    color: Colors.BLACK,
  },
  "black-pawn-2-7": {
    id: "black-pawn-2-7",
    name: Figures.PAWN,
    y: 7,
    x: 2,
    color: Colors.BLACK,
  },
  "black-pawn-3-7": {
    id: "black-pawn-3-7",
    name: Figures.PAWN,
    y: 7,
    x: 3,
    color: Colors.BLACK,
  },
  "black-pawn-4-7": {
    id: "black-pawn-4-7",
    name: Figures.PAWN,
    y: 7,
    x: 4,
    color: Colors.BLACK,
  },
  "black-pawn-5-7": {
    id: "black-pawn-5-7",
    name: Figures.PAWN,
    y: 7,
    x: 5,
    color: Colors.BLACK,
  },
  "black-pawn-6-7": {
    id: "black-pawn-6-7",
    name: Figures.PAWN,
    y: 7,
    x: 6,
    color: Colors.BLACK,
  },
  "black-pawn-7-7": {
    id: "black-pawn-7-7",
    name: Figures.PAWN,
    y: 7,
    x: 7,
    color: Colors.BLACK,
  },
  "black-pawn-8-7": {
    id: "black-pawn-8-7",
    name: Figures.PAWN,
    y: 7,
    x: 8,
    color: Colors.BLACK,
  },

  "white-rook-1-1": {
    id: "white-rook-1-1",
    name: Figures.ROOK,
    x: 1,
    y: 1,
    color: Colors.WHITE,
  },
  "white-knight-2-1": {
    id: "white-knight-2-1",
    name: Figures.KNIGHT,
    x: 2,
    y: 1,
    color: Colors.WHITE,
  },
  "white-bishop-3-1": {
    id: "white-bishop-3-1",
    name: Figures.BISHOP,
    x: 3,
    y: 1,
    color: Colors.WHITE,
  },
  "white-queen-4-1": {
    id: "white-queen-4-1",
    name: Figures.QUEEN,
    x: 4,
    y: 1,
    color: Colors.WHITE,
  },
  "white-king-5-1": {
    id: "white-king-5-1",
    name: Figures.KING,
    x: 5,
    y: 1,
    color: Colors.WHITE,
  },
  "white-bishop-6-1": {
    id: "white-bishop-6-1",
    name: Figures.BISHOP,
    x: 6,
    y: 1,
    color: Colors.WHITE,
  },
  "white-knight-7-1": {
    id: "white-knight-7-1",
    name: Figures.KNIGHT,
    x: 7,
    y: 1,
    color: Colors.WHITE,
  },
  "white-rook-8-1": {
    id: "white-rook-8-1",
    name: Figures.ROOK,
    x: 8,
    y: 1,
    color: Colors.WHITE,
  },

  "white-pawn-1-2": {
    id: "white-pawn-1-2",
    name: Figures.PAWN,
    y: 2,
    x: 1,
    color: Colors.WHITE,
  },
  "white-pawn-2-2": {
    id: "white-pawn-2-2",
    name: Figures.PAWN,
    y: 2,
    x: 2,
    color: Colors.WHITE,
  },
  "white-pawn-3-2": {
    id: "white-pawn-3-2",
    name: Figures.PAWN,
    y: 2,
    x: 3,
    color: Colors.WHITE,
  },
  "white-pawn-4-2": {
    id: "white-pawn-4-2",
    name: Figures.PAWN,
    y: 2,
    x: 4,
    color: Colors.WHITE,
  },
  "white-pawn-5-2": {
    id: "white-pawn-5-2",
    name: Figures.PAWN,
    y: 2,
    x: 5,
    color: Colors.WHITE,
  },
  "white-pawn-6-2": {
    id: "white-pawn-6-2",
    name: Figures.PAWN,
    y: 2,
    x: 6,
    color: Colors.WHITE,
  },
  "white-pawn-7-2": {
    id: "white-pawn-7-2",
    name: Figures.PAWN,
    y: 2,
    x: 7,
    color: Colors.WHITE,
  },
  "white-pawn-8-2": {
    id: "white-pawn-8-2",
    name: Figures.PAWN,
    y: 2,
    x: 8,
    color: Colors.WHITE,
  },
};
