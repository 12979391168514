import React from "react";
import styles from "../Board/Board.module.scss";
import { BoardNumberByLetter, Colors } from "../../../helpers/types";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface CellProps {
  color: Colors;
  x: string;
  y: number;
  cellClicked: (x: number, y: number) => void;
  isAvailableForMove?: boolean;
  isHavingFigure?: boolean;
  isSelected?: boolean;
}

const Cell: React.FC<CellProps> = (props: CellProps) => {
  return (
    <li
      onClick={() => props.cellClicked(BoardNumberByLetter[props.x], props.y)}
      id={`cell-${props.x}-${props.y}`}
      className={cx({
        cell: true,
        cellWhite: props.color === Colors.WHITE,
        cellBlack: props.color === Colors.BLACK,
        availableCell: props.isAvailableForMove && !props.isHavingFigure,
        cellSelected: props.isSelected,
      })}
    >
      <div
        className={cx({
          cellCircle: true,
          cellCircleShow: props.isAvailableForMove && !props.isHavingFigure,
        })}
      ></div>
    </li>
  );
};

export default Cell;
