import { useContext, useEffect, useState, createContext } from "react";
import { auth } from "../helpers/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { AuthContextType } from "../helpers/types";

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showHowTo, setShowHowTo] = useState(false);
  const [howToID, setHowToID] = useState(0);
  const [loginMethod, setLoginMethod] = useState("login");

  const [showChessPriceRadio, setShowChessPriceRadio] = useState(false);
  const [showChessPayment, setShowChessPayment] = useState(false);
  const [chessPaymentStatus, setChessPaymentStatus] = useState(false);
  const [chessTimer, setChessTimer] = useState(5);
  const [url, setUrl] = useState("");

  function signup(email: string, password: string, displayName: string) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        return updateProfile(user, {
          displayName: displayName,
        });
      })
      .then(() => {
        console.log("Profile updated successfully");
      })
      .catch((error) => {
        console.error("Error during sign up or profile update:", error);
      });
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value: AuthContextType = {
    currentUser,
    showLogin,
    setShowLogin,
    loginMethod,
    setLoginMethod,
    login,
    signup,
    logout,
    resetPassword,
    showHowTo,
    setShowHowTo,
    howToID,
    setHowToID,
    chessPaymentStatus,
    setChessPaymentStatus,
    showChessPayment,
    setShowChessPayment,
    chessTimer,
    setChessTimer,
    showChessPriceRadio,
    setShowChessPriceRadio,
    url,
    setUrl,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
