import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowDown } from "react-icons/io";
import companyLogo from "../utils/images/Nikunj-Logo-1.png";
import { headerDropdownData } from "../utils/data/headerDropdownData";
import Container from "./Container";
import Login from "./Login";
import { useAuth } from "../context/AuthContext";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [dropdown, setDropdown] = useState({
    about: false,
    projects: false,
    investors: false,
  });

  const { setShowLogin, setLoginMethod, currentUser, logout } = useAuth();

  const handleMouseEnter = (name: string) => {
    setDropdown((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleMouseLeave = (name: string) => {
    setDropdown((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const handleDropdownClick = (name: string) => {
    setDropdown((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLoginClick = (method) => {
    setLoginMethod(method);
    setShowLogin(true);
  };

  async function handleLogout() {
    try {
      await logout();
    } catch {
      console.log("Failed to log out");
    }
  }

  return (
    <header
      className={`sticky top-0 z-50 p-3 ${
        scrolled ? "shadow-lg shadow-slate-800" : "shadow-lg shadow-black"
      } bg-[#0b1022]`}
    >
      <Container className="flex items-center justify-between gap-8">
        <a href="/" className="flex items-center h-20" aria-label="Home">
          <img
            width={130}
            height={65}
            src={companyLogo}
            alt="Company Logo"
            loading="lazy"
          />
        </a>
        <div className="flex items-center justify-end lg:gap-8 w-full">
          <nav className="hidden lg:flex justify-start items-center gap-7 font-semibold ml-4 text-white">
            {currentUser && (
              <a
                href="/dashboard"
                className="hover:text-yellow-400 items-center gap-1 cursor-pointer"
              >
                Dashboard
              </a>
            )}
            <a
              href="/about-us"
              className="hover:text-yellow-400 items-center gap-1 cursor-pointer"
            >
              About Us
            </a>
            {headerDropdownData.map((dropdownItem) => (
              <div
                key={dropdownItem.id}
                className="relative"
                onMouseEnter={() => handleMouseEnter(dropdownItem.name)}
                onMouseLeave={() => handleMouseLeave(dropdownItem.name)}
              >
                <div
                  className={`${
                    dropdown[dropdownItem.name] ? "text-yellow-400" : ""
                  } inline-flex items-center gap-1 cursor-pointer`}
                >
                  {dropdownItem.name.charAt(0).toUpperCase() +
                    dropdownItem.name.slice(1)}{" "}
                  <IoIosArrowDown />
                </div>
                {dropdown[dropdownItem.name] && (
                  <div
                    className={`absolute bg-white shadow-lg shadow-gray-200 rounded-lg py-2 z-50 ${
                      dropdownItem.name === "policies" ? "w-48" : ""
                    }`}
                  >
                    {dropdownItem.items.map((item) => (
                      <a
                        key={item.label}
                        href={item.link}
                        className="block px-4 py-2 text-primary hover:text-yellow-600"
                      >
                        {item.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <a
              href="/security"
              className="hover:text-yellow-400 items-center gap-1 cursor-pointer"
            >
              Security
            </a>
            <a
              href="/contact"
              className="hover:text-yellow-400 items-center gap-1 cursor-pointer"
            >
              Contact Us
            </a>
          </nav>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="lg:hidden font-bold text-xl hover:text-gray-500 ms-auto"
            aria-label={showMenu ? "Close Menu" : "Open Menu"}
          >
            {showMenu ? (
              <GrClose />
            ) : (
              <GiHamburgerMenu className="text-white text-3xl" />
            )}
          </button>
          {showMenu && (
            <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-sm z-40"></div>
          )}
          <div
            className={`fixed overflow-scroll top-0 right-0 h-full bg-gray-100 shadow-lg transition-transform transform ${
              showMenu ? "translate-x-0" : "translate-x-full"
            } w-80 lg:hidden z-50`}
          >
            <div className="p-4 flex justify-between items-center">
              <span className="text-lg font-semibold">Menu</span>
              <button
                onClick={() => setShowMenu(!showMenu)}
                className="font-bold text-xl hover:text-gray-500"
                aria-label="Close Menu"
              >
                {showMenu && <GrClose />}
              </button>
            </div>
            <nav className="flex flex-col items-start p-4 gap-4 font-semibold">
              {headerDropdownData.map((dropdownItem) => (
                <div key={dropdownItem.name} className="w-full">
                  <div
                    className="flex justify-between items-center w-full cursor-pointer"
                    onClick={() => handleDropdownClick(dropdownItem.name)}
                    aria-expanded={dropdown[dropdownItem.name]}
                  >
                    <span>
                      {dropdownItem.name.charAt(0).toUpperCase() +
                        dropdownItem.name.slice(1)}
                    </span>
                    <IoIosArrowDown />
                  </div>
                  {dropdown[dropdownItem.name] && (
                    <div className="pl-4">
                      {dropdownItem.items.map((item) => (
                        <a
                          key={item.label}
                          href={item.link}
                          className="block py-2 hover:text-primary"
                        >
                          {item.label}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <a
                href="/job-openings"
                className="hover:text-gray-500 w-full text-left mb-8"
              >
                Careers
              </a>
            </nav>
            <button
              onClick={
                currentUser ? handleLogout : () => handleLoginClick("login")
              }
              className="text-primary bg-yellow-400 shadow-md shadow-black hover:shadow-black hover:bg-secondary hover:text-white focus:ring-4 focus:ring-yellow-400 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none w-full"
            >
              {currentUser ? "Log Out" : "SignUp/Login"}
            </button>
          </div>
          <div className="flex items-center justify-end gap-4">
            <button
              onClick={
                currentUser ? handleLogout : () => handleLoginClick("login")
              }
              className="text-primary bg-yellow-400 shadow-md shadow-black hover:shadow-black hover:bg-secondary hover:text-white focus:ring-4 focus:ring-yellow-400 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none hidden lg:flex"
            >
              {currentUser ? "Log Out" : "SignUp/Login"}
            </button>
          </div>
        </div>
      </Container>
      <Login />
    </header>
  );
}
