import React from "react";
import { RadioButtonNewProps } from "../helpers/types";
import "../utils/styles/radioButton.css";

function RadioButtonNew(props: RadioButtonNewProps) {
  const {
    value,
    checked,
    onChange,
    label,
    PriceValue,
    className = "",
    style = {},
    backgroundColor = "transparent",
  } = props;

  const handleChange = () => {
    onChange(value);
  };
  return (
    <div className={`radio-button-container ${className}`} style={style}>
      <label className="radio-button-label gap-2">
        <input
          type="radio"
          value={value}
          checked={checked}
          onChange={handleChange}
          className="hidden"
        />
        <span
          className={`checkmark border border-gray-300 rounded-full w-4 h-4 inline-block mr-2 ${
            checked ? "checked" : ""
          }`}
          style={{ backgroundColor: checked ? backgroundColor : "transparent" }}
        ></span>

        {label}
        {PriceValue && (
          <p className="radio-PriceValue text-sm text-pretty font-medium">
            / {PriceValue}
          </p>
        )}
      </label>
    </div>
  );
}

export default RadioButtonNew;
