// import poolTableImg from "../../utils/images/POOL-TABLE.png";
// import bowlingBallImg from "../../utils/images/bowling-ball.png";
import playChessImg from "../../utils/images/PLAY-CHESS.png";
// import pokerGameImg from "../../utils/images/POKER-GAME.png";

import { HomeCardDataItems } from "../../helpers/types";

export const homeCardData: HomeCardDataItems[] = [
  // {
  //   id: 1,
  //   img: poolTableImg,
  //   label: "Pool Table",
  // },
  // {
  //   id: 2,
  //   img: bowlingBallImg,
  //   label: "Bowling Ball",
  // },
  {
    id: 3,
    img: playChessImg,
    label: "Chess",
  },
  // {
  //   id: 4,
  //   img: pokerGameImg,
  //   label: "Rummy",
  // },
];
