import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  resetGame,
  selectColor,
  selectIsGameStarted,
  setColor,
} from "../../../redux/gameSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Colors } from "../../../helpers/types";
import "../../../utils/styles/reset.css";
import "../../../utils/styles/chessGame.css";
import { useAuth } from "../../../context/AuthContext";
import ChessGamePrice from "../../ChessGamePrice";
import Payment from "../../Payment";
import styles from "../Main/Main.module.scss";
import RadioButton from "../RadioButton/RadioButton";

const Main: React.FC = () => {
  const navigate = useNavigate();
  const color = useAppSelector(selectColor);
  const isGameStarted = useAppSelector(selectIsGameStarted);
  const dispatch = useAppDispatch();
  const [gameStarted, setGameStarted] = useState(false);

  const { showChessPriceRadio, setShowChessPriceRadio, chessPaymentStatus } =
    useAuth();

  console.log("isGameStarted " + isGameStarted);
  console.log("gameStarted " + gameStarted);
  console.log("chessPaymentStatus " + chessPaymentStatus);

  const radioChanged = (id: string) => {
    dispatch(setColor(id as Colors));
  };

  const handlePayment = async () => {
    setShowChessPriceRadio(true);

    // Polling for payment completion
    return new Promise<void>((resolve) => {
      const checkPaymentStatus = setInterval(() => {
        if (chessPaymentStatus) {
          clearInterval(checkPaymentStatus);
          resolve(); // Payment completed successfully
        }
      }, 1000); // Check every second
    });
  };

  useEffect(() => {
    if (chessPaymentStatus) {
      dispatch(resetGame());
      setGameStarted(true);
      navigate("/chessGame/play");
    }
  }, [chessPaymentStatus, dispatch, navigate]);

  const startNewGame = async () => {
    if (!showChessPriceRadio) {
      try {
        await handlePayment(); // Wait for payment to complete
      } catch (error) {
        console.error("Payment failed:", error);
        return;
      }
    }
  };

  return (
    <div className="ChessGame">
      <div className="ChessGame-bg"></div>

      {!gameStarted ? (
        <div className={styles.wrapper}>
          <div className={styles.logo}></div>
          <h2>Choose side</h2>
          <form>
            <RadioButton
              value="White"
              handleChange={radioChanged}
              name="radio"
              isChecked={color === "white"}
            />
            <RadioButton
              value="Black"
              handleChange={radioChanged}
              name="radio"
              isChecked={color === "black"}
            />
          </form>
          {isGameStarted && chessPaymentStatus && (
            <Link to="/chessGame/play" className={styles.button}>
              Continue
            </Link>
          )}
          <button onClick={startNewGame} className={styles.button}>
            Start new game
          </button>

          <ChessGamePrice />
          <Payment />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default Main;
