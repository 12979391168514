import { useEffect, useState, useTransition, FormEvent } from "react"; // Import FormEvent
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth";
import { auth } from "../helpers/firebase";

export default function Login() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [resendCountdown, setResendCountdown] = useState(0);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isPending, startTransition] = useTransition();
  const {
    signup,
    login,
    showLogin,
    setShowLogin,
    loginMethod,
    setLoginMethod,
    resetPassword,
  } = useAuth();
  const navigate = useNavigate();

  const closeModal = () => {
    setShowLogin(false);
  };

  const showOtpOnClick = (e: FormEvent) => {
    e.preventDefault();
    setShowOtp(true);
  };

  async function handleSignup(e: FormEvent) {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return setError("Passwords do not match");
    }
    if (!mobileno) {
      return setError("Mobile number is required");
    }

    try {
      setError("");
      setLoading(true);

      // Signup with email and password
      await signup(email, password, name);

      // Send OTP to the mobile number
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        "+91" + mobileno,
        recaptchaVerifier
      );
      setConfirmationResult(confirmationResult);
      setShowOtp(true); // Show OTP input
    } catch (error) {
      setError("Failed to sign up or send OTP. Please try again.");
      setLoading(false);
    }
  }

  async function verifyOtpAndSignup() {
    if (!confirmationResult || otp.length !== 6) {
      setError("Please enter a valid OTP");
      return;
    }

    try {
      setError("");
      setLoading(true);

      // Verify OTP
      await confirmationResult.confirm(otp);

      // User is already signed up with email and password,
      // just update the profile with the display name and possibly save the mobile number in the database
      const user = auth.currentUser;
      if (user) {
        await updateProfile(user, {
          displayName: name,
        });

        // Optionally, save the mobile number in your database
      }

      closeModal();
      navigate("/Dashboard");
    } catch (error) {
      setError("Failed to verify OTP or complete signup. Please try again.");
      setLoading(false);
    }
  }

  async function handleLogin(e: FormEvent) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(email, password);
      closeModal();
      navigate("/Dashboard");
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  async function handleReset(e: FormEvent) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await resetPassword(email);
      closeModal();
      navigate("/Dashboard");
    } catch {
      setError("Failed to reset password");
    }
    setLoading(false);
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      { size: "invisible" }
    );
    setRecaptchaVerifier(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    };
  }, []);

  useEffect(() => {
    const hasEnteredAllDigits = otp.length === 6;
    if (hasEnteredAllDigits) {
      verifyOtp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const verifyOtp = async () => {
    startTransition(() => {
      setError("");
      if (!confirmationResult) {
        setError("Please request OTP first.");
        return;
      }
      confirmationResult
        .confirm(otp)
        .then((userCredential) => {
          const user = userCredential.user;
          return updateProfile(user, {
            displayName: name,
          });
        })
        .then(() => {
          navigate("/Dashboard");
          setOtp("");
          setMobileno("");
          setShowOtp(false);
          setRecaptchaVerifier(null);
          setConfirmationResult(null);
          setResendCountdown(0);
          setShowLogin(false);
        })
        .catch(() => setError("Failed to verify OTP. Please check the OTP."));
    });
  };

  const requestOtp = async (e?: FormEvent) => {
    e?.preventDefault();
    if (!recaptchaVerifier) {
      setError("RecaptchaVerifier is not initialized.");
      return;
    }
    setResendCountdown(60);
    startTransition(() => {
      setError("");
      signInWithPhoneNumber(auth, "+91" + mobileno, recaptchaVerifier)
        .then((result) => {
          console.log("OTP sent successfully:", result);
          setConfirmationResult(result);
        })
        .catch((err) => {
          console.error("Error sending OTP:", err);
          setResendCountdown(0);
          setError("Failed to send OTP. Please try again.");
        });
    });
  };

  return (
    <>
      {showLogin && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            {error && (
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-red-500">{error}</h2>
              </div>
            )}
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                {loginMethod === "login"
                  ? "Login"
                  : loginMethod === "otp"
                  ? "Login with OTP"
                  : loginMethod === "forgot"
                  ? "Forgot Password"
                  : "Sign Up & Play Now"}
              </h2>
              <button onClick={closeModal}>
                <GrClose className="text-2xl hover:text-gray-500" />
              </button>
            </div>
            {loginMethod === "login" && (
              <form className="flex flex-col gap-4">
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <button
                  type="submit"
                  disabled={loading}
                  onClick={handleLogin}
                  className="bg-primary text-white p-2 rounded hover:bg-secondary"
                >
                  Login
                </button>
              </form>
            )}
            {loginMethod === "otp" && (
              <form className="flex flex-col gap-4">
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={mobileno}
                  onChange={(e) => setMobileno(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="text"
                  placeholder="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className={`border border-gray-300 p-2 rounded ${
                    showOtp && confirmationResult ? "" : "hidden"
                  }`}
                />
                <button
                  type="submit"
                  disabled={
                    loading || !mobileno || isPending || resendCountdown > 0
                  }
                  onClick={showOtp ? requestOtp : showOtpOnClick}
                  className="bg-primary text-white p-2 rounded hover:bg-secondary"
                >
                  {resendCountdown > 0
                    ? `Resend OTP in ${resendCountdown}`
                    : isPending
                    ? "Sending OTP"
                    : "Send OTP"}
                </button>
              </form>
            )}
            {loginMethod === "signup" && (
              <form
                className="flex flex-col gap-4"
                onSubmit={showOtp ? verifyOtpAndSignup : handleSignup}
              >
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={mobileno}
                  onChange={(e) => setMobileno(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                {showOtp && (
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="border border-gray-300 p-2 rounded"
                  />
                )}
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-primary text-white p-2 rounded hover:bg-secondary"
                >
                  {showOtp ? "Verify OTP & Complete Signup" : "Sign Up"}
                </button>
              </form>
            )}
            {loginMethod === "forgot" && (
              <form className="flex flex-col gap-4">
                <input
                  type="text"
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 p-2 rounded"
                />
                <button
                  type="submit"
                  onClick={handleReset}
                  disabled={loading}
                  className="bg-primary text-white p-2 rounded hover:bg-secondary"
                >
                  Reset Password
                </button>
              </form>
            )}
            <div className="mt-4">
              {loginMethod !== "signup" && (
                <button
                  onClick={() => setLoginMethod("signup")}
                  className="text-primary hover:underline"
                >
                  Create an Account
                </button>
              )}
              {loginMethod === "signup" && (
                <button
                  onClick={() => setLoginMethod("login")}
                  className="text-primary hover:underline"
                >
                  Already have an account? Login
                </button>
              )}
              {loginMethod !== "otp" && (
                <button
                  onClick={() => setLoginMethod("otp")}
                  className="text-primary hover:underline ml-4"
                >
                  Login with OTP
                </button>
              )}
              {loginMethod === "login" && (
                <button
                  onClick={() => setLoginMethod("forgot")}
                  className="text-primary hover:underline ml-4"
                >
                  Forgot Password
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div id="recaptcha-container" />
    </>
  );
}
