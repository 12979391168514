import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function PrivacyPolicy() {
  return (
    <>
      <BreadCrumb pageName="Privacy Policy" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Privacy & Policy</h1>
          <p className="text-md mt-4 text-gray-600">
            This privacy policy forms part of our Website's Terms of Service. By
            accepting the Terms of Service and this Privacy Policy on the
            registration page, you consent to provide sensitive personal data or
            personal information. Please read this Privacy Policy carefully.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            1. Policy Objective
          </h2>
          <p className="text-md text-gray-600">
            We respect your privacy and assure you that any information provided
            by you is protected and will be dealt with according to this Policy
            and the applicable laws. To avail of the services offered, you may
            have to provide certain information to us.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            2. What information do we collect:
          </h2>
          <h2 className="text-xl font-bold">Sensitive Personal Data:</h2>
          <ul className="list-disc pl-5">
            <li>
              <p>Account password</p>
            </li>
            <li>
              <p>
                Financial information such as bank account or credit card
                details
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold">Other Personal Information:</h2>
          <ul className="list-disc pl-5">
            <li>
              <p>Name</p>
            </li>
            <li>
              <p>Date of birth</p>
            </li>
            <li>
              <p>Telephone number</p>
            </li>
            <li>
              <p>Postal/Contact address</p>
            </li>
            <li>
              <p>PAN number (as applicable)</p>
            </li>
            <li>
              <p>The IP address of your computer, browser type and language.</p>
            </li>
            <li>
              <p>The date and the time during which you accessed the site.</p>
            </li>
            <li>
              <p>
                The address of the website which you may have used to link to
                any Website
              </p>
            </li>
            <li>
              <p>Your photograph for testimonials and other promotions.</p>
            </li>
            <li>
              <p>
                Such other information that is defined as sensitive personal
                data or information in law.
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold underline underline-offset-4">
            3. Data Security:
          </h2>
          <p className="text-md text-gray-600">
            We take appropriate security measures to protect against
            unauthorized access to or unauthorized alteration, disclosure or
            destruction of data. These include internal reviews of our data
            collection, storage and processing practices and security measures.
            We also undertake measures including appropriate encryption and
            physical security measures to guard against unauthorized access to
            systems where we store personal data. Play NikunjStar has a
            comprehensive information security program and information security
            policies which contain managerial, technical, operational and
            physical security control measures adopted by Play NikunjStar for
            the protection of Sensitive Personal Date and Other Personal
            Information.
          </p>
        </div>
      </Container>
    </>
  );
}
