import companyLogo from "../utils/images/Nikunj-Star-Logo-1.png";
import Container from "./Container";

export default function Footer() {
  return (
    <footer className="bg-black">
      <Container>
        <div className="flex flex-col md:flex-row gap-6 lg:gap-16 py-6 md:px-10">
          <div className="w-full md:w-2/5">
            <div className="flex flex-col xl:flex-row items-start xl:items-center justify-between gap-4 xl:gap-12">
              <a href="/">
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  loading="lazy"
                  width={150}
                />
              </a>
              <h4 className="text-yellow-400 text-left text-xl lg:text-2xl font-bold leading-snug text-shadow-lg mb-2 uppercase">
                NikunjStar Info Solutions
                <br />
                <span className="text-white text-lg lg:text-xl">
                  (OPC) Private Limited
                </span>
              </h4>
            </div>
            <p className="text-left text-gray-300">
              NikunjStar-The Most popular Indian Cards Games,play NikunjStar
              Gamesonline win real rupees with Millions of players around the
              world in real-time!
            </p>
          </div>

          <div className="w-full md:w-3/5 flex flex-col md:flex-row justify-between gap-1">
            <div className="w-full md:w-1/3 mb-4 md:mb-0">
              <div className="">
                <h2 className="mb-2 text-lg font-semibold uppercase text-yellow-400">
                  Company
                </h2>
                <ul className="text-gray-400 font-normal">
                  <li className="mb-1">
                    <a href="/" className="text-white hover:text-yellow-400">
                      Home
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="/about-us"
                      className="text-white hover:text-yellow-400"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="/security"
                      className="text-white hover:text-yellow-400"
                    >
                      Security
                    </a>
                  </li>
                  <li className="mb-6">
                    <a
                      href="/contact"
                      className="text-white hover:text-yellow-400"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="">
                <h2 className="mb-2 text-lg font-semibold uppercase text-yellow-400">
                  policies
                </h2>
                <ul className="text-gray-400 font-normal">
                  <li className="mb-1">
                    <a
                      href="/terms-conditions"
                      className="text-white hover:text-yellow-400"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="/privacy-policy"
                      className="text-white hover:text-yellow-400"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="/refund-policy"
                      className="text-white hover:text-yellow-400"
                    >
                      Refund Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-2/3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.6973425133215!2d75.78434027597164!3d26.91309886007298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db40c3c477fd7%3A0x4938717211fa0f2c!2sJacob%20Rd%2C%20Jaipur%2C%20Rajasthan%20302007!5e0!3m2!1sen!2sin!4v1723029277864!5m2!1sen!2sin"
                loading="lazy"
                className="w-full h-[350px] md:h-[300px]"
                title="map"
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
      <div className="py-6 px-4 bg-gray-800 flex items-center justify-center">
        <span className="text-sm text-gray-300 sm:text-center">
          © 2024{" "}
          <a href="/" className="text-white hover:text-yellow-400">
            Nikunj Star Info
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
