import Container from "../components/Container";
import HomeCard from "../components/HomeCard";
import HowToPlay from "../components/HowToPlay";
import TabPanel from "../components/TabPanel";
import { useAuth } from "../context/AuthContext";
import tabData from "../utils/data/tabsData";
// import Img from "../utils/images/Pop-up-Banner.jpg";

export default function Dashboard() {
  const { currentUser } = useAuth();
  return (
    <>
      {/* <Container> */}
      <section className="bg-[#4e65af] pb-8">
        <div className="text-center h-24 flex items-center justify-center text-white font-bold text-lg">
          {currentUser.displayName}
        </div>
        {/* <img src={Img} alt="" /> */}
        <Container>
          <TabPanel tabsData={tabData} />
        </Container>
      </section>
      <section className="bg-[#f8f9ff] py-14">
        <div className="flex flex-col items-center">
          <h4 className="md:text-4xl text-3xl font-extrabold p-4 pb-2 text-primary">
            Indian Games
          </h4>
          <span className="border-b-4 border-yellow-600 w-40 mb-10 shadow-sm shadow-slate-900"></span>
        </div>
        <HomeCard />
        <HowToPlay />
      </section>
      {/* </Container> */}
    </>
  );
}
