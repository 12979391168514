import { howToPlayDataItems } from "../../helpers/types";

export const howToPlayData: howToPlayDataItems[] = [
  {
    id: 1,
    gameName: "Pool Table",
    gameDesc: [
      "8 Ball Pool is a popular billiards game played with 15 numbered balls and a cue ball. The object of the game is to legally pocket either all the solid-colored balls (1-7) or all the striped balls (9-15) and then pocket the 8 ball to win the game.",
      "The primary objective is to be the first player to legally pocket all of your assigned group of balls (solids or stripes) and then pocket the 8 ball in a called pocket.",
      "The game is played with 15 object balls, numbered 1 through 15, and a white cue ball.",
      "The balls are racked in a triangle, with the 8 ball in the center of the rack. The first ball (the apex) is placed on the foot spot.",
    ],
    gameRules: [
      {
        id: 1,
        rule: "Breaking:",
        desc: [
          "A player must break the rack by striking the cue ball with the cue stick, aiming to hit the racked balls.",
          "If a ball is pocketed on the break, the player continues to shoot.",
          "If no ball is pocketed, the opponent takes the next shot.",
        ],
      },
      {
        id: 2,
        rule: "Choosing Solids or Stripes:",
        desc: [
          "After the break, the table is considered 'open,' meaning that either player can attempt to pocket any ball (except the 8 ball).",
          "The first player to legally pocket a ball after the break must continue to pocket the balls of the same group (solids or stripes) for the rest of the game.",
        ],
      },
      {
        id: 3,
        rule: "Taking Shots:",
        desc: [
          "The player must always hit one of their group's balls first. Failing to do so is considered a foul.",
          "A shot is only valid if the cue ball hits a ball from the player's group and at least one ball hits a rail or is pocketed.",
        ],
      },
      {
        id: 4,
        rule: "Fouls:",
        desc: [
          "Common fouls include scratching (pocketing the cue ball), failing to hit your group's ball first, or not hitting a rail after contact.",
          "If a foul occurs, the opponent gets 'ball in hand,' meaning they can place the cue ball anywhere on the table.",
        ],
      },
      {
        id: 5,
        rule: "Winning the Game:",
        desc: [
          "After pocketing all of your group's balls, you must call the pocket where you intend to sink the 8 ball.",
          "If you successfully pocket the 8 ball in the called pocket, you win the game.",
          "If you successfully pocket the 8 ball in the called pocket, you win the game.",
        ],
      },
    ],
  },
  {
    id: 2,
    gameName: "Bowling Ball",
    gameDesc: [
      "Bowling is a game where the goal is to knock down as many pins as possible. The game consists of 10 frames, and the player with the highest score at the end wins.",
      "The game is played on a virtual bowling lane with 10 pins arranged in a triangular formation at the end.",
      "Players use a bowling ball to try to knock down the pins. Each frame allows the player to roll the ball twice unless they knock down all pins in the first roll.",
    ],
    gameRules: [
      {
        id: 1,
        rule: "Starting the Game:",
        desc: [
          "Select your bowling ball. Some games may allow you to choose from different types of balls that vary in weight, size, and spin capabilities.",
        ],
      },
      {
        id: 2,
        rule: "Aiming:",
        desc: [
          "Use the mouse or arrow keys to aim the direction of your shot. Align your aim with the center pin for the best chance of knocking down all the pins.",
        ],
      },
      {
        id: 3,
        rule: "Setting Power and Spin:",
        desc: [
          "Click and hold the mouse or press a button to set the power of your shot. Release at the desired power level.",
          "Some games allow you to add spin to the ball. After setting the power, use the mouse or controls to adjust the spin (left or right). Spin can help the ball curve towards the pins.",
        ],
      },
      {
        id: 4,
        rule: "Rolling the Ball:",
        desc: [
          "Once the aim, power, and spin are set, release the mouse or button to roll the ball down the lane.",
          "The ball will travel down the lane and, if aimed and powered correctly, will knock down the pins.",
        ],
      },
      {
        id: 5,
        rule: "Scoring:",
        desc: [
          "Each frame gives you two chances to knock down all 10 pins.",
          "If you knock down all 10 pins on the first roll, it's called a strike. You score 10 points plus the total of your next two rolls.",
          "If you knock down all 10 pins in two rolls, it's called a spare. You score 10 points plus the total of your next roll.",
          "If you don't knock down all the pins in two rolls, you score based on the number of pins you knocked down.",
        ],
      },
      {
        id: 6,
        rule: "10th Frame:",
        desc: [
          "In the 10th frame, if you roll a strike or spare, you get extra rolls to complete the frame. A strike gives you two extra rolls, and a spare gives you one.",
        ],
      },
      {
        id: 7,
        rule: "Winning the Game:",
        desc: [
          "The game consists of 10 frames, and the player with the highest score at the end wins.",
          "A perfect game score is 300, achieved by rolling 12 strikes in a row (one strike in each of the first nine frames, and three in the 10th).",
        ],
      },
    ],
  },
  {
    id: 3,
    gameName: "Chess",
    gameDesc: [
      "Chess is a strategic board game played between two players. The goal is to checkmate your opponent's king, meaning the king is in a position to be captured (in 'check') and cannot escape capture.",
      "The game is played on an 8x8 grid called a chessboard, with alternating light and dark squares.",
      "Each player starts with 16 pieces: 1 king, 1 queen, 2 rooks, 2 knights, 2 bishops, and 8 pawns.",
      "The pieces are arranged with rooks in the corners, knights next to the rooks, bishops next to the knights, the queen on her color, and the king next to the queen. Pawns are lined up in front of the other pieces.",
      "White moves first, and players alternate turns.",
    ],
    gameRules: [
      {
        id: 1,
        rule: "Moving Pieces:",
        desc: [
          "King: Moves one square in any direction (horizontally, vertically, or diagonally). The king cannot move into check (a position where it could be captured).",
          "Queen: Moves any number of squares in any direction (horizontally, vertically, or diagonally).",
          "Rook: Moves any number of squares horizontally or vertically.",
          "Bishop: Moves any number of squares diagonally.",
          "Knight: Moves in an 'L' shape—two squares in one direction and then one square perpendicular. Knights can jump over other pieces.",
          "Pawn: Moves forward one square but captures diagonally. Pawns can move two squares forward on their first move. If a pawn reaches the opponent’s back rank (8th row), it can be promoted to any other piece (usually a queen).",
        ],
      },
      {
        id: 2,
        rule: "Special Moves:",
        desc: [
          "Castling: The king moves two squares towards a rook, and the rook moves to the square next to the king. This move can only be done if neither the king nor the rook has moved before, there are no pieces between them, and the king is not in check or moving through check.",
          "En Passant: If a pawn moves two squares forward from its starting position and lands beside an opponent’s pawn, the opponent can capture it as if it had only moved one square. This move must be done immediately after the pawn moves two squares.",
          "Promotion: When a pawn reaches the opponent's back rank, it can be promoted to any piece (except a king), usually a queen.",
        ],
      },
      {
        id: 3,
        rule: "Check and Checkmate:",
        desc: [
          "Check: When a king is attacked, it is in check. The player must make a move to get out of check by moving the king, blocking the check with another piece, or capturing the attacking piece.",
          "Checkmate: When the king is in check and cannot escape, the game ends, and the player delivering checkmate wins.",
        ],
      },
      {
        id: 4,
        rule: "Stalemate and Draws:",
        desc: [
          "Stalemate: If a player has no legal moves and their king is not in check, the game ends in a stalemate (a draw).",
          "Draw by Agreement: Players can agree to a draw at any time.",
          "Threefold Repetition: If the same position occurs three times with the same player to move, either player can claim a draw.",
          "Fifty-Move Rule: If fifty moves are made by each player without any pawn movement or capture, either player can claim a draw.",
        ],
      },
      {
        id: 5,
        rule: "Winning the Game:",
        desc: [
          "The game is won by checkmating the opponent's king.",
          "A player can also win if their opponent resigns or runs out of time (in timed games).",
        ],
      },
    ],
  },
  {
    id: 4,
    gameName: "Rummy",
    gameDesc: [
      "Rummy is a card game where the objective is to form sets or runs with your cards and be the first to meld them all. The player who successfully does so wins the game.",
      "Rummy is typically played with 2 to 6 players using one or two standard 52-card decks, plus jokers.",
      "Each player is dealt a specific number of cards: For 2 players: 10 cards each, For 3-4 players: 7 cards each, For 5-6 players: 6 cards each.",
      "The remaining cards are placed face down to form the draw pile, and the top card is placed face up to start the discard pile.",
      "Card Values: Number cards (2-10): Face value, Face cards (J, Q, K): 10 points each, Aces: 1 point each (in some variations, aces can be high and worth 11 points), Jokers: Can be used as wild cards to substitute for any card in a set or run.",
    ],
    gameRules: [
      {
        id: 1,
        rule: "Drawing a Card:",
        desc: [
          "On their turn, each player must draw a card, either from the draw pile or the discard pile.",
        ],
      },
      {
        id: 2,
        rule: "Forming Sets and Runs:",
        desc: [
          "Set: A group of 3 or 4 cards of the same rank but different suits (e.g., 7♦ 7♠ 7♣).",
          "Run (Sequence): A sequence of 3 or more consecutive cards of the same suit (e.g., 4♠ 5♠ 6♠).",
          "Jokers can be used to replace any card in a set or run.",
          "In some variations, a run must contain at least 3 cards, and one run must be a 'pure sequence' (without any jokers).",
        ],
      },
      {
        id: 3,
        rule: "Melding Cards:",
        desc: [
          "After drawing a card, players can lay down sets or runs (melds) if they have formed them.",
          "Players are not required to lay down their melds until they are ready to go out.",
        ],
      },
      {
        id: 4,
        rule: "Discarding a Card:",
        desc: [
          "At the end of their turn, a player must discard one card to the discard pile.",
          "The discarded card should be one that is least likely to help the opponent form a set or run.",
        ],
      },
      {
        id: 5,
        rule: "Winning the Game:",
        desc: [
          "The game continues until a player successfully melds all their cards, leaving them with no cards in hand or just one discard.",
          "This player declares 'Rummy' and wins the round.",
          "If a player declares 'Rummy' but still has cards that cannot form valid sets or runs, they lose the game, and the opponent wins.",
        ],
      },
      {
        id: 6,
        rule: "Scoring:",
        desc: [
          "If a player wins, they score points based on the cards left in the opponents' hands.",
          "The values are calculated based on the points of the unmelded cards. Aces, face cards, and jokers are worth 10 points each, and number cards are worth their face value.",
          "The game can be played over several rounds, and the player with the highest score at the end wins.",
        ],
      },
    ],
  },
];
