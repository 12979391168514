import { Tab } from "../../helpers/types";

const tabsData: Tab[] = [
  {
    label: "Game Ponts",
    content: [
      {
        value: 0.05,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
  {
    label: "Best of 2",
    content: [
      {
        value: 0.9,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
  {
    label: "Best of 3",
    content: [
      {
        value: 0.41,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
  {
    label: "51 Pool",
    content: [
      {
        value: 0.5,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
  {
    label: "101 Pool",
    content: [
      {
        value: 0.09,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
  {
    label: "201 Pool",
    content: [
      {
        value: 0.1,
        pointPlaying: "325 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.05,
        pointPlaying: "552 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "225 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.1,
        pointPlaying: "462 Playing",
        player: "1 / 6",
        status: "Seating",
      },
      {
        value: 0.25,
        pointPlaying: "186 Playing",
        player: "0 / 2",
        status: "Open",
      },
      {
        value: 0.25,
        pointPlaying: "519 Playing",
        player: "0 / 6",
        status: "Open",
      },
      {
        value: 0.5,
        pointPlaying: "117 Playing",
        player: "1 / 2",
        status: "Seating",
      },
      {
        value: 0.5,
        pointPlaying: "340 Playing",
        player: "2 / 6",
        status: "Seating",
      },
    ],
  },
];

export default tabsData;
