import { GrClose } from "react-icons/gr";
import { useAuth } from "../context/AuthContext";
import { howToPlayData as data } from "../utils/data/howToPlayData";
import { useNavigate } from "react-router-dom";

export default function HowToPlay() {
  const {
    showHowTo,
    setShowHowTo,
    howToID,
    setHowToID,
    setShowLogin,
    currentUser,
  } = useAuth();
  console.log(howToID);
  const navigate = useNavigate();
  const closeModal = () => {
    setShowHowTo(false);
    setHowToID(0);
  };
  const openLogin = () => {
    closeModal();
    if (currentUser) {
      navigate("/chessGame");
    } else {
      setShowLogin(true);
    }
  };
  return (
    <>
      {showHowTo && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 overflow-y-auto overflow-x-hidden top-0 right-0 bottom-0 left-0">
          <div className="bg-white rounded-lg p-10 w-2/3 mx-auto my-7">
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h1 className="text-3xl font-bold font-Kanit text-primary">
                  How To Play: {data[howToID].gameName}
                </h1>
                <div className="flex">
                  <button
                    className="text-primary bg-yellow-400 shadow-md shadow-black hover:shadow-black hover:bg-secondary hover:text-white focus:ring-4 focus:ring-yellow-400 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none hidden lg:flex"
                    onClick={openLogin}
                  >
                    Play Game
                  </button>
                  <button onClick={closeModal}>
                    <GrClose className="text-2xl hover:text-gray-500 ms-12 " />
                  </button>
                </div>
              </div>
              <div className="modalPopup max-h-[700px] overflow-auto">
                <ul className="list-disc pl-9 font-Kanit !mt-8">
                  {data[howToID].gameDesc.map((content) => (
                    <li className="mb-2 last:mb-0">
                      <p className="text-md text-gray-600">{content}</p>
                    </li>
                  ))}
                </ul>
                <h3 className="text-xl font-bold font-Kanit">
                  Rules and Instructions
                </h3>
                <ul className="list-decimal pl-5 font-Kanit">
                  {data[howToID].gameRules.map((content) => (
                    <li className="mb-2 last:mb-0" key={content.id}>
                      <h3 className="text-md font-bold">{content.rule}</h3>
                      <ul className="list-disc pl-5">
                        {content.desc.map((desc) => (
                          <li className="mb-2 last:mb-0">
                            <p className="text-md text-gray-600">{desc}</p>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
