import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function TermsConditions() {
  return (
    <>
      <BreadCrumb pageName="Terms & Conditions" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Terms & Conditions</h1>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Terms of Service
          </h2>
          <p className="text-md mt-4 text-gray-600">
            NikunjStar (hereinafter referred as "NikunjStar" or "We") offers
            online games including and not limited to rummy, fantasy sports and
            poker through the web-portal NikunjStar app and NikunjStar,
            (collectively referred to as the "Portal" or "Platform") (Play
            NikunjStar referred to herein as "Play NikunjStar" or "we" or "us"
            or "our"). Any person utilizing the Portal or any of its features
            including participation in the various contests, games ("Amusement
            Facilities") being conducted on the Portal, be referred to as "User"
            or "you" or "your" and shall be bound by these Terms of Service and
            our Privacy Policy. The services provided on our Portal are
            collectively referred to as the "Services". As per changes made to
            the Telangana State Gaming Act 1974 and Andhra Pradesh Gaming Act
            1974, online games of skill with entry fees for prizes have been
            restricted in the States of Telangana and Andhra Pradesh. In
            compliance with these changes, we have made changes to our services
            and have stopped offering online games for entry fees and prizes in
            Telangana and Andhra Pradesh. We also do not offer our services in
            the states of Assam, Nagaland and Sikkim which together with the
            States of Andhra Pradesh and Telangana are referred as "Restricted
            States". If you access the Service for making deposit(s)
            /withdrawal(s) from or playing Game(s) from a jurisdiction where
            Games are not permitted, you shall be entirely liable for any legal
            or penal consequences, and we shall be entitled to forfeit the
            balance in your account.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            1. Introduction
          </h2>
          <p className="text-md text-gray-600">
            Your use of the products and/or services on the Portal (hereinafter
            referred to as "Services") currently offered or to be offered in
            future by Play NikunjStar, its subsidiaries, affiliates, licensors,
            associates and partners (hereinafter referred as "Play NikunjStar")
            through the Portal [(which comprises of
            https://www.nikunjstarinfo.com/ and www.nikunjstarinfo.com/ (each
            referred as "Website" and together as "Websites") and the NikunjStar
            and NikunjStar apps (each referred as "App" and together as
            "Apps")], are all subject to and governed by these Terms of Service,
            Privacy Policy, Help, Promotions, Add Cash, My Account and
            Bring-A-Friend sections collective or respectively for each Website
            and App including the sub-sections of these sections, as the case
            may be (hereinafter referred as "Terms") You understand that the
            Terms will be binding on you. You agree that Services offered on the
            Portal can be accessed only in accordance with the Terms and you
            shall be responsible to comply with the Terms at all times. You are
            responsible to be aware of and agree to abide by the Terms as
            published and periodically amended or modified by Play NikunjStar.
            If any of the Terms are determined to be unlawful, invalid, void or
            unenforceable for any reason by any judicial or quasi - judicial
            body in India, it will not affect the validity and enforceability of
            the remaining Terms. Our failure or delay to act or exercise any
            right or remedy with respect to a breach of any of the Terms by you
            shall not be construed as a waiver of our right to act with respect
            to the said breach or any prior, concurrent, subsequent or similar
            breaches. If a promotion, game, event, competition or tournament is
            organized by us on a Portal, it shall be governed by these Terms and
            any supplementary terms and conditions which may be specifically
            applied for that promotion, game, event, competition or tournament
            (hereinafter collectively referred as "Terms" or "Terms of
            Service").
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            2. Applicability:
          </h2>
          <p className="text-md text-gray-600">
            Before Registering With Us, You Should Carefully Read And Review
            These Terms Provided By Play Games24x7 Which Are Applicable To All
            The Services On The Portal And Also The Website Or App-specific
            Conditions And Rules Applicable To The Specific Website Or App.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            3. Legality:
          </h2>
          <p className="text-md text-gray-600">
            You may only use the Services to play Cash Games (as defined below)
            if you are 18 years of age or over. Access to our Services or any
            part thereof may be restricted by us from time to time in our sole
            decision. You confirm that you are not accessing the Services to
            play Cash Games from any Restricted State or outside India.
          </p>
        </div>
      </Container>
    </>
  );
}
