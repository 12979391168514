import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollButton";
import NotFound from "./pages/NotFound";
import OurStory from "./pages/OurStory";
import SecurityPage from "./pages/SecurityPage";
import ContactUs from "./pages/ContactUs";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundCancellations from "./pages/RefundCancellations";
import DataRetention from "./pages/DataRetention";
import { AuthProvider, useAuth } from "./context/AuthContext";
// import ChessGame from "./pages/ChessGame";
import Main from "./components/ChessGame/Main/Main";
import Board from "./components/ChessGame/Board/Board";
import { Provider } from "react-redux";
import store from "./redux/store";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/about-us" element={<OurStory />} />
            <Route path="/security" element={<SecurityPage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundCancellations />} />
            <Route path="/data-retention" element={<DataRetention />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/chessGame"
              element={
                <PrivateRoute>
                  <Main />
                </PrivateRoute>
              }
            >
              <Route
                path="/chessGame/play"
                element={
                  <PrivateRoute>
                    <Board />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
          <ScrollButton />
          <Footer />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Navigate to="/" replace />;
  }
  return children;
}

export default App;
