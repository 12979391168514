import HomeCard from "../components/HomeCard";
import bannerLeftImg from "../utils/images/Nikunj.-Desktop_Screen.png";
import bannerBgVideo from "../utils/videos/bg1.mp4";
import Container from "../components/Container";
import cashRupeesImg from "../utils/images/cash-rupee.png";
import ClientSlider from "../components/ClientSlider";

export default function Home() {
  return (
    <>
      <section className="bannerSection relative h-96 md:h-[530px] lg:h-[580px] xl:h-[600px]">
        <div className="h-96 md:h-[530px] lg:h-[580px] xl:h-[600px] bg-primary absolute inset-0 opacity-100"></div>
        <video
          src={bannerBgVideo}
          autoPlay
          muted
          loop
          className="mb-0 h-96 md:h-[530px] lg:h-[580px] xl:h-[600px] absolute inset-0 w-full object-cover opacity-50"
        />

        <div className="h-full bannerContent bg-primary lg:bg-transparent p-4 relative w-full shadow-sm shadow-slate-950 lg:shadow-none lg:shadow-transparent z-10 flex items-center justify-center md:justify-between">
          <div className="hidden md:block w-full md:w-1/2 lg:w-1/2 xl:w-2/5">
            <img
              src={bannerLeftImg}
              alt="bgImage"
              className="mb-0 h-96 sm:h-[400px] md:h-[445px] lg:h-[580px] xl:h-[600px] object-cover absolute inset-0 opacity-100 top-1/2 -translate-y-2/4 -left-32"
              loading="lazy"
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/2 xl:w-3/5">
            <h1 className=" tracking-widest text-4xl md:text-4xl lg:text-5xl lg:px-16 lg:tracking-normal font-bold uppercase leading-normal text-center text-yellow-400 font-Kanit text-shadow-md">
              Join Games <br />
              <span className="text-white">
                & <br /> Get Daily Cash Bonus
              </span>
            </h1>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f9ff] py-14">
        <div className="flex flex-col items-center">
          <h4 className="md:text-4xl text-3xl font-extrabold p-4 pb-2 text-primary">
            Indian Games
          </h4>
          <span className="border-b-4 border-yellow-600 w-40 mb-10 shadow-sm shadow-slate-900"></span>
        </div>
        <HomeCard />
      </section>

      <section className="bg-slate-950 py-20">
        <Container>
          <div className="flex items-center justify-center flex-col">
            <div className="text-center space-y-3">
              <h4 className="md:text-4xl text-3xl font-extrabold text-yellow-400">
                NikunjStar Games{" "}
                <span className="text-white">
                  to Win real money in cash Rupees
                </span>
              </h4>
              <p className="text-gray-100">
                NikunjStar games is online Rummy portal completely owned and
                operated by NikunjStar Games.
              </p>
            </div>
            <div className="mt-12">
              <img src={cashRupeesImg} alt="Cash Rupees" />
            </div>
          </div>
        </Container>
      </section>
      <ClientSlider />
    </>
  );
}
