import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function SecurityPage() {
  return (
    <>
      <BreadCrumb pageName="Security" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Exceptions</h1>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Secure Payment Gateways
          </h2>
          <p className="text-md text-gray-600">
            We partner with reputable payment processors that comply with the
            highest industry standards for secure financial transactions.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            User Authentication
          </h2>
          <p>
            We ensure user privacy is protected. Regular privacy audits help us
            maintain or exceed industry standards.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Privacy Protection
          </h2>
          <p className="text-md text-gray-600">
            Our infrastructure includes advanced DDoS mitigation techniques to
            maintain service availability at all times.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Regular Security Audits
          </h2>
          <p className="text-md text-gray-600">
            Our platform uses advanced authentication systems, including
            password protection, two-factor authentication, and biometric
            options, ensuring enhanced account security.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            DDoS Protection
          </h2>
          <p className="text-md text-gray-600">
            We conduct regular audits and penetration tests to strengthen our
            security infrastructure and address vulnerabilities.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Incident Response
          </h2>
          <p className="text-md text-gray-600">
            We offer resources to help users protect their accounts and identify
            security threats like phishing.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            User Education
          </h2>
          <p className="text-md text-gray-600">
            Regular backups and a comprehensive disaster recovery plan ensure
            quick restoration of services in case of an incident.
          </p>
        </div>
      </Container>
    </>
  );
}
