import { commentsItems } from "../../helpers/types";

export const comments: commentsItems[] = [
  {
    title: "Won ₹20,000",
    text: "I am playing Nikunj Star Info from last 1 year, I have won more than 1 Lac, they have best rewards structure & you get rewards for lifetime.",
    stars: 5,
    author: "Mr Sunil Singh",
  },
  {
    title: "Won ₹20,000",
    text: "I am playing Nikunj Star Info from last 1 year, I have won more than 1 Lac, they have best rewards structure & you get rewards for lifetime.",
    stars: 4,
    author: "Diana Patel",
  },
  {
    title: "Won ₹20,000",
    text: "I am playing Nikunj Star Info from last 1 year, I have won more than 1 Lac, they have best rewards structure & you get rewards for lifetime.",
    stars: 5,
    author: "Richa Rajyalaxmi",
  },
  {
    title: "Won ₹20,000",
    text: "I am playing Nikunj Star Info from last 1 year, I have won more than 1 Lac, they have best rewards structure & you get rewards for lifetime.",
    stars: 4,
    author: "Diana Patel",
  },
  {
    title: "Won ₹20,000",
    text: "I am playing Nikunj Star Info from last 1 year, I have won more than 1 Lac, they have best rewards structure & you get rewards for lifetime.",
    stars: 5,
    author: "Mr Sunil Singh",
  },
];
