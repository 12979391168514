import { useCallback, useState } from "react";
import { TabsProps } from "../helpers/types";
import "../utils/styles/tabPanel.css";
import { CiFilter } from "react-icons/ci";

const TabPanel = ({ tabsData }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedValues, setSelectedValues] = useState({
    high: false,
    medium: false,
    low: false,
    player2: false,
    player6: false,
    player8: false,
    open: false,
    seating: false,
  });

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
    // Reset all filters to false
    setSelectedValues({
      high: false,
      medium: false,
      low: false,
      player2: false,
      player6: false,
      player8: false,
      open: false,
      seating: false,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setSelectedValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  // const filteredContent = tabsData[activeIndex].content.filter((item) => {
  //   const matchValue = selectedValues[item.value];
  //   const matchPlayer = selectedValues[`player${item.player}`];
  //   const matchStatus = selectedValues[item.status];

  const filteredContent = useCallback(() => {
    return tabsData[activeIndex].content
      .filter((item) => {
        const playerNumbers = item.player.split("/").map(Number);
        const totalPlayers = playerNumbers[1]; // Only consider the total players

        const matchPlayer = selectedValues[`player${totalPlayers}`]; // Match against the total players only
        const matchStatus = selectedValues[item.status];

        const withinHighRange = selectedValues.high && item.value >= 0.25;
        const withinMediumRange =
          selectedValues.medium && item.value >= 0.1 && item.value < 0.25;
        const withinLowRange = selectedValues.low && item.value < 0.1;

        const matchValue =
          withinHighRange || withinMediumRange || withinLowRange;

        return (
          (selectedValues.high || selectedValues.medium || selectedValues.low
            ? matchValue
            : true) &&
          (selectedValues.player2 ||
          selectedValues.player6 ||
          selectedValues.player8
            ? matchPlayer
            : true) &&
          (selectedValues.open || selectedValues.seating ? matchStatus : true)
        );
      })
      .sort((a, b) => {
        if (selectedValues.high) {
          return b.value - a.value; // Descending order for high values
        }
        if (selectedValues.low) {
          return a.value - b.value; // Ascending order for low values
        }
        // No sorting if medium is selected or no specific filter is selected
        return 0;
      });
  }, [tabsData, activeIndex, selectedValues]);

  return (
    <>
      <div
        className="bg-darkBlueColor flex items-start gap-8 border-b-4 rounded-lg border-t-2 border-t-black border-b-yellow-500"
        style={{ boxShadow: "rgb(0, 0, 0) 0px -17px 16px -13px" }}
      >
        <div className="flex flex-col md:flex-row gap-8 w-full lg:w-3/4">
          {/* Tabs Navigation */}
          <div className="border-r border-gray-200 md:border-r-0">
            <div className="py-4 flex justify-center">
              <h5 className="text-gray-300 font-Kanit font-bold text-2xl uppercase leading-8 tracking-widest">
                beginner's
              </h5>
            </div>
            <div className="flex flex-col">
              {tabsData.map((tab, index) => (
                <button
                  key={index}
                  className={`py-3 px-4 transition-colors duration-300 tab-button text-center ${
                    index === activeIndex
                      ? "bg-darkBlue1 text-yellow-400 font-bold after:absolute after:top-1/2 after:-translate-y-2/4 after:left-full after:w-0 after:h-0 after:border-t-8 after:border-b-8 after:border-l-[13px] after:border-[#081329] after:border-t-transparent after:border-b-transparent rounded-tr-md rounded-br-md shadow-custom mb-[0.2rem] w-[350px] hover:w-[350px] !focus:shadow-custom"
                      : "bg-darkBlueColor text-gray-300 hover:bg-darkBlue1 hover:text-yellow-400 hover:font-bold hover:after:absolute hover:after:top-1/2 hover:after:-translate-y-2/4 hover:after:left-full hover:after:w-0 hover:after:h-0 hover:after:border-t-8 hover:after:border-b-8 hover:after:border-l-[13px] hover:after:border-[#081329]  hover:after:border-t-transparent hover:after:border-b-transparent hover:shadow-custom w-[350px] hover:rounded-tr-md hover:rounded-br-md transition duration-300 border-b-[1px] border-gray-500"
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          {/* Tab Content */}
          <div className="w-full px-4 py-0 -mt-2">
            <div
              className="w-full rounded-tl-2xl rounded-tr-2xl border-t-2 border-t-black"
              style={{ boxShadow: "0px -8px 3px -6px rgb(0, 0, 0)" }}
            >
              <div className="grid grid-cols-3 gap-4 bg-darkBlue1 p-2 text-white text-center rounded-tl-xl rounded-tr-xl">
                <div className="font-bold">Pt. Value</div>
                <div className="font-bold">Players</div>
                <div className="font-bold">Status</div>
              </div>
              <div className="filterContent text-center max-h-[500px] min-h-[500px] overflow-y-auto bg-[#354054]">
                {filteredContent().length > 0 ? (
                  filteredContent().map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      className={`grid grid-cols-3 gap-4 py-2 text-center filterList ${
                        rowIndex % 2 === 0 ? "bg-slate-700" : "bg-darkBlue1"
                      }`}
                    >
                      <div className="text-yellow-500 flex flex-col items-center justify-center">
                        {row.value}
                        <span className="block text-gray-400 text-sm pt-2">
                          {row.pointPlaying}
                        </span>
                      </div>
                      <div className="text-yellow-500 flex items-center justify-center">
                        {row.player}
                      </div>
                      <div className="text-yellow-500 flex items-center justify-center">
                        {row.status}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="py-4">No matching results</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/4 pr-8">
          <div className="py-4">
            <h5 className="text-white font-Manrope font-semibold text-lg capitalize leading-8 tracking-wider flex items-center gap-3">
              <CiFilter className="text-2xl" />
              Filters
            </h5>
          </div>

          <div className="mb-4 filtersSection">
            <h6 className="text-white mb-2 font-Manrope font-semibold text-lg border-b border-dashed pb-1">
              Point Value
            </h6>
            <ul className="space-y-4 my-4 pl-4">
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="high"
                  checked={selectedValues.high}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="high" className="text-gray-300">
                  High
                </label>
              </li>
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="medium"
                  checked={selectedValues.medium}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="medium" className="text-gray-300">
                  Medium
                </label>
              </li>
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="low"
                  checked={selectedValues.low}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="low" className="text-gray-300">
                  Low
                </label>
              </li>
            </ul>
          </div>

          <div className="mb-4 filtersSection">
            <h6 className="text-white mb-2 font-Manrope font-semibold text-lg border-b border-dashed">
              Players
            </h6>
            <ul className="space-y-4 my-4 pl-4">
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="player2"
                  checked={selectedValues.player2}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="player2" className="text-gray-300">
                  Players 2
                </label>
              </li>
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="player6"
                  checked={selectedValues.player6}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="player6" className="text-gray-300">
                  Players 6
                </label>
              </li>
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="player8"
                  checked={selectedValues.player8}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="player8" className="text-gray-300">
                  Players 8
                </label>
              </li>
            </ul>
          </div>

          <div className="mb-4 filtersSection">
            <h6 className="text-white mb-2 font-Manrope font-semibold text-lg border-b border-dashed">
              Status
            </h6>
            <ul className="space-y-4 my-4 pl-4">
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="open"
                  checked={selectedValues.open}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="open" className="text-gray-300">
                  Open
                </label>
              </li>
              <li className="flex items-center justify-between">
                <input
                  type="checkbox"
                  id="seating"
                  checked={selectedValues.seating}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-yellow-400 bg-darkBlue1 border-gray-300 rounded focus:ring-yellow-400"
                />
                <label htmlFor="seating" className="text-gray-300">
                  Seating
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabPanel;
