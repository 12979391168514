import { Dispatch, SetStateAction } from "react";
import { IconType } from "react-icons";

export interface JobItems {
  _id: string;
  job_id: number;
  name: string;
  designation: string;
  description: string[];
  skills: string;
  framework: string;
  job_type: string;
  job_location: string;
}

export interface CareerDropdownItems {
  name: string;
  items: string[];
}

export interface BreadCrumbProps {
  pageName: string;
}

export interface JobFormDatas {
  name: string;
  email: string;
  phone: string;
  coverLetter: string;
  resume: File | null;
}
export interface FormDatas {
  name: string;
  email: string;
  phone: string;
  coverLetter: string;
  resume: File | null;
}

export interface HeaderDropdownItems {
  id: number;
  name: string;
  items: { label: string; link: string }[];
}

export interface HomeCardDataItems {
  id: number;
  img: string;
  label: string;
}

export interface commentsItems {
  title: string;
  text: string;
  stars: number;
  author: string;
}

export interface projectDataItems {
  label: string;
  id: number;
  img: string;
  href: string;
  home: boolean;
  imgSm: string;
  imgLg: string;
  link: string;
  content: string[];
}

export interface contactCardDataItems {
  id: number;
  icon: IconType;
  label: string;
  designation: string;
  address: string;
}

export interface Report {
  label: string;
  file: string;
}

export interface Quarter {
  label: string;
  reports: Report[];
}

export interface AccordionItem {
  title: string;
  quarters: Quarter[];
}

export interface Tab {
  label: string;
  content: {
    value: number;
    pointPlaying: string;
    player: string;
    status: string;
  }[];
}

export interface TabsProps {
  tabsData: Tab[];
}

export interface HtmlContentProps {
  content: string;
}

export interface ClientFormDatas {
  name: string;
  email: string;
  phone: string;
  subject: string;
}

export interface ProjectPageProps {
  data: projectDataItems;
}

export interface Report {
  label: string;
  file: string;
}

export interface Quarter {
  label: string;
  reports: Report[];
}

export interface AccordionItem {
  title: string;
  quarters: Quarter[];
}

export interface AccordionProps {
  items: AccordionItem[];
  initialOpenIndex: number;
}

export interface ManagementDataItem {
  id: number;
  img: string;
  name: string;
  designation: string;
  data: string;
}

export interface ManagementCardProps {
  data: ManagementDataItem;
}

export interface JobData {
  _id: string;
  job_id: number;
  name: string;
  designation: string;
  description: Array<string>;
  framework: string;
  skills: string;
  job_type: string;
  job_location: string;
}

export interface JobCardProps {
  searchText: string;
  selectedFilters: {
    category: string;
    type: string;
    locations: string;
  };
}

export interface CareerDropdownProps {
  selectedFilters: {
    category: string;
    type: string;
    locations: string;
  };
  setSelectedFilters: Dispatch<
    SetStateAction<{
      category: string;
      type: string;
      locations: string;
    }>
  >;
}

export interface InvestorsCornerProps {
  title: string;
}
export interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
}
export interface ContactFormDatas {
  name: string;
  email: string;
  phone: string;
  subject: string;
}

export interface AuthContextType {
  currentUser: any;
  signup: (
    email: string,
    password: string,
    displayName: string
  ) => Promise<any>;
  login: (email: string, password: string) => Promise<any>;
  showLogin: boolean;
  setShowLogin: (show: boolean) => void;
  loginMethod: string;
  setLoginMethod: (method: string) => void;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  showHowTo: boolean;
  setShowHowTo: (how: boolean) => void;
  howToID: number;
  setHowToID: (how: number) => void;
  chessPaymentStatus: boolean;
  showChessPayment: boolean;
  setChessPaymentStatus: (set: boolean) => void;
  setShowChessPayment: (show: boolean) => void;
  chessTimer: number;
  // setChessTimer: (chess: number) => void;
  setChessTimer: React.Dispatch<React.SetStateAction<number>>;
  showChessPriceRadio: boolean;
  setShowChessPriceRadio: (set: boolean) => void;
  url: string;
  setUrl: (set: string) => void;
}

interface GameRule {
  id: number;
  rule: string;
  desc: string[];
}

export interface howToPlayDataItems {
  id: number;
  gameName: string;
  gameDesc: string[];
  gameRules: GameRule[];
}

// game Chess
export enum Colors {
  WHITE = "white",
  BLACK = "black",
}

export enum Figures {
  BISHOP = "bishop",
  KING = "king",
  KNIGHT = "knight",
  PAWN = "pawn",
  QUEEN = "queen",
  ROOK = "rook",
}

export const BoardLettersByNumber: { [key: number]: string } = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
};

export const BoardNumberByLetter: { [key: string]: number } = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
};

export interface FigureData {
  id: string;
  name: Figures;
  x: number;
  y: number;
  color: Colors;
}

export interface GameState {
  color: Colors;
  figures: { [key: string]: FigureData };
  gameWon: Colors | null;
  isGameStarted: boolean;
}

export interface RadioButtonProps {
  value: string;
  handleChange: (id: string) => void;
  isChecked?: boolean;
  name?: string;
}

export interface FigureProps {
  figure: FigureData;
  figureClicked: (figure: FigureData) => void;
  isSelected?: boolean;
  isEatable?: boolean;
}

export interface RadioButtonNewProps {
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  label: string;
  PriceValue?: string;
  className?: string;
  style?: React.CSSProperties;
  backgroundColor?: string;
}
