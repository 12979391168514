import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function DataRetention() {
  return (
    <>
      <BreadCrumb pageName="Data Retention Policy" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Data Retention Policy</h1>
          <p className="text-md mt-4 text-gray-600">
            Your privacy is of utmost importance to us and protection of your
            Personal Data as disclosed by you to us is a key commitment for us.
            During your usage of our platform, we may collect and generate
            various data points about you as elaborated below. In this, we are
            governed by the provisions of applicable law in India including the
            Information Technology Act 2000 and the Information Technology
            (Reasonable security practices and procedures and sensitive personal
            data or information) Rules, 2011 to maintain the privacy of your
            Personal Data. We collect and process your Personal Data only to the
            extent necessary for us to process your requirement(s) and in the
            manner as detailed hereinafter below in section II and III, and in
            accordance with such applicable law. In case you choose not to share
            your Personal Data as required, with us, we may not be able to carry
            out your transaction.
          </p>
          <p className="text-md mt-4 text-gray-600">
            "Personal Data" means data relating to you that is directly or
            indirectly identifiable, having regard to any characteristic, trait,
            attribute or any other feature of your identity or any combination
            of such features with any other information. Personal Data does not
            mean information that is freely available or accessible in public
            domain or furnished under the Right to Information Act, 2005 or any
            other law for the time being in force. Personal Data includes
            Sensitive Personal Data, which relates to password, financial
            information such as bank account or credit card or debit card or
            other payment instrument details, physical, physiological and mental
            health condition, sexual orientation, medical records and history,
            biometrics or any other data categorised as sensitive under
            applicable law in India.
          </p>
          <h2 className="text-xl font-bold">
            I. Types of Information we collect
          </h2>
          <p className="text-md text-gray-600">
            We may collect following information from you, as you have disclosed
            to us:
          </p>
          <ol className="list-decimal pl-5">
            <li>
              <p>
                Personal details (e.g. name, contact details including,
                residential address, date of birth, documents such as identity
                card / passport details / Aadhaar details / PAN / Voter ID /
                driving license, and/or education details) provided by you to us
                to avail our services.
              </p>
            </li>
            <li>
              <p>
                Your details including transaction history, balances, payment
                details, for effecting transfer of monies through various
                payment channels provided by us.
              </p>
            </li>
            <li>
              <p>
                Financial details (e.g. income, expenses, and/or credit history)
                needed as part of request for some of our products/services;
              </p>
            </li>
            <li>
              <p>
                Images of documents/ photos required to avail any of our
                products/services.
              </p>
            </li>
            <li>
              <p>
                voice recordings of our conversations with our customer care
                agent with you to address your queries/grievances.
              </p>
            </li>
            <li>
              <p>
                employment details as part of our record retention and required
                under applicable law including Prevention of Money Laundering
                (Maintenance of Records) Rules, 2005
              </p>
            </li>
            <li>
              <p>
                specimen signature(s) for processing of your instructions
                received by us through our various payment and delivery
                channels;
              </p>
            </li>
            <li>
              <p>
                opinions provided by you to us by way of feedback or responses
                to surveys;
              </p>
            </li>
            <li>
              <p>
                Information obtained from your mobile device by way of using our
                app like device location, communication information including
                phone number, SIM Serial Number, contacts and call logs, device
                information (including storage, model, IMEI, Network Carrier
                information), transactional and promotional SMS/app
                notifications;
              </p>
            </li>
            <li>
              <p>
                Access to your mobile camera device for the functioning of many
                in-app functionalities for recording video including, but not
                limited to video KYC process and scan & pay etc.;
              </p>
            </li>
            <li>
              <p>
                Access to the microphone to record audio for carrying out video
                KYC process and voice typing etc.;
              </p>
            </li>
            <li>
              <p>
                Access to files from your mobile device such as audio, video
                files for the functioning of many in-app functionalities
                including, but not limited to in-app chat;
              </p>
            </li>
          </ol>
          <h2 className="text-xl font-bold">
            II. Sharing and Storage of your Personal Data
          </h2>
          <p className="text-md text-gray-600">
            Any Personal Data that we have access to shall never be shared
            without your consent. In various processes / submission of
            applications / availment of product / service offerings, we even
            seek your explicit consent to use / share your Personal Data.
          </p>
          <p className="text-md text-gray-600">
            In our business and operational processes, we only share the data on
            a partial and “need-to-know” basis to designated personnel or
            partners or service providers.
          </p>
          <p className="text-md text-gray-600">
            We may process, store and retain your Personal Data on our servers
            where the data centers are located, and/or on the servers of third
            parties having contractual relationships with us. All Sensitive
            Personal Data is stored in India. We will retain your data for the
            period that you continue to be our customer and for such period as
            required to comply with any obligation under any law for the time
            being in force.
          </p>
          <p className="text-md text-gray-600">
            We will share your data with competent / legal / statutory /
            regulatory agencies / authorities or partners / service providers
            acting on our behalf (as the case may be) in following cases:
          </p>
          <ul className="list-decimal pl-5">
            <li>
              <p>
                For enabling the provision of the products / services availed by
                you, strictly on a “need to know” basis and subject to
                applicable laws.
              </p>
            </li>
            <li>
              <p>
                Where it is directed or required by legal / regulatory /
                statutory / governmental authorities / judiciary under any
                applicable laws / regulations or judicial pronouncement though a
                legally obligated request.
              </p>
            </li>
            <li>
              <p>
                Where it is directed or required by legal / regulatory /
                statutory / governmental authorities / judiciary under any
                applicable laws / regulations or judicial pronouncement though a
                legally obligated request.
              </p>
            </li>
          </ul>
          <p className="text-md text-gray-600">
            When you make transactions through our services, we may collect and
            store information about you to process your requests and
            automatically complete forms for future transactions, including (but
            not limited to) your phone number, address, email, billing
            information and payment preferences. This information may be shared
            with third-parties which assist in processing and fulfilling your
            requests, including Payment Card Industry (PCI) compliant payment
            gateway processors, and for providing you with products / services
            to better serve your needs and interests. Such sharing shall be in
            accordance with your instructions and subject to appropriate
            confidentiality and security requirements.
          </p>
          <h2 className="text-xl font-bold">
            III. Usage of Your Personal Data
          </h2>
          <p className="text-md text-gray-600">
            We use your Personal Data in our business activities for providing
            our partners’ products / services and to perform, among other
            actions, the following:
          </p>
          <ul className="list-decimal pl-5">
            <li>
              <p>
                To facilitate the transactions or report on these transactions;
              </p>
            </li>
            <li>
              <p>
                To undertake research and analytics for offering or improving
                our products / services and their security and service quality;
              </p>
            </li>
            <li>
              <p>
                To check and process your requirements submitted to us for
                products / services and / or instructions or requests received
                from you in respect of these products / services;
              </p>
            </li>
            <li>
              <p>
                To share with you, updates on changes to the products / services
                and their terms and conditions including platform’s terms and
                conditions;
              </p>
            </li>
            <li>
              <p>
                To take up or investigate any complaints / claims / disputes;
              </p>
            </li>
            <li>
              <p>To respond to your queries or feedback submitted by you;</p>
            </li>
            <li>
              <p>
                To verify your identity for us to provide products / services to
                you;
              </p>
            </li>
            <li>
              <p>To carry due diligence checks as lawfully required by us;</p>
            </li>
            <li>
              <p>
                To monitor and review products / services from time to time;
              </p>
            </li>
            <li>
              <p>
                To undertake financial / regulatory / management reporting, and
                create and maintain various risk management models;
              </p>
            </li>
            <li>
              <p>To conduct audits and for record keeping purposes;</p>
            </li>
            <li>
              <p>
                To enable us to show you advertisements which are relevant to
                you and your interests, selective offers and promotions, search
                results, location-based services;
              </p>
            </li>
            <li>
              <p>
                To auto populate such third party pages basis your click on
                advertisement instances on our website and your proceeding to
                such pages;
              </p>
            </li>
            <li>
              <p>
                To comply with the requirements of applicable laws / regulations
                and / or court orders / regulatory directives received by us;
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold">
            IV. Purging of your Personal Data
          </h2>
          <p className="text-md text-gray-600">
            You may choose to delete your account at any point of time by making
            such choice in mobile WAP site or mobile application. With this we
            will no longer provide your data for external processing as
            mentioned above. However, we retain your Personal Data as long as
            the purpose for its usage exists, after which the same is disposed
            off by us except for any record retention required as per applicable
            law. The provisions of various laws require your transaction logs to
            be stored for longer periods post the deletion of an account.
            Further, in the event of the pendency of any legal / regulatory
            proceeding or receipt of any legal and / or regulatory direction to
            that effect, we may be required by the law of the land to retain
            your Personal Data for longer periods.
          </p>
          <h2 className="text-xl font-bold">V. Cookie Policy</h2>
          <ul className="list-decimal pl-5">
            <li>
              <p>
                Please note that a “cookie” is a small piece of information
                stored by a web server on a web browser so it can be later read
                back from that browser.
              </p>
            </li>
            <li>
              <p>
                We may use cookie and tracking technology depending on the
                features offered.
              </p>
            </li>
            <li>
              <p>
                No Personal Data will be collected via cookies and other
                tracking technology; however, if you previously provided
                Personal Data, cookies may be tied to such information.
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold">VI. Links to other websites</h2>
          <p className="text-md text-gray-600">
            Our website may contain links to other websites which are not
            maintained by us. This privacy policy only applies to us. You are
            requested to read the other websites’ privacy policies when visiting
            these websites.
          </p>
          <h2 className="text-xl font-bold">
            VII. Reasonable Security Practices and Procedures
          </h2>
          <p className="text-md text-gray-600">
            We take various steps and measures to protect the security of your
            Personal Data from misuse, loss, unauthorised access, modification
            or disclosure. We use latest secure server layers encryption and
            access control on our systems. Our safety and security processes are
            audited by a third party cyber security audit agency from time to
            time.
          </p>
          <p className="text-md text-gray-600">
            We have provided multiple levels of security to safeguard your app
            by Login / Logout option, and App Lock feature for payments, that
            can be enabled by you. We also ensure the device binding so that the
            same login cannot be used on different device without any additional
            authentication / OTP. Please do not share your Account’s login,
            password and OTP details with anybody.
          </p>
          <p className="text-md text-gray-600">
            When you submit credit or payment card information, we encrypt the
            information in compliance with Payment Card Industry (PCI) data
            security standards.
          </p>
          <p className="text-md text-gray-600">
            While we observe reasonable security measures to protect your
            Personal Information on all our digital platforms, security risks
            may still arise for reasons outside of our control such as hacking,
            virus dissemination, force majeure events, breach of firewall etc.
            Please note that the above mentioned measures do not guarantee
            absolute protection to the Personal Information.
          </p>
          <h2 className="text-xl font-bold">VIII. Policy Review & Updates</h2>
          <p className="text-md text-gray-600">
            This policy will be reviewed by us as and when required and the same
            may be subject to change at any point in time. The latest & most
            updated policy can always be found at ………………..
          </p>
          <p className="text-md text-gray-600">
            While we will make reasonable efforts to keep you posted on any
            updates to this privacy policy, to make sure that you are aware of
            any changes, we recommend that you review this policy periodically.
          </p>
          <p className="text-md text-gray-600">
            This Privacy Policy shall apply uniformly to our desktop website,
            mobile WAP site & mobile applications.
          </p>
          <p className="text-md text-gray-600">
            We reserve the right to modify/update this Privacy Policy. We will
            post the changes to our Privacy Policy, if any, on this page. Please
            check the “Last updated” legend at the bottom of this page to see
            when this Privacy Policy was last updated. We encourage you to check
            the same to be informed of how, We are committed in protecting your
            information and providing you with the improved content.
          </p>
          <p className="text-md text-gray-600">
            In this policy, the words “we”, “ours” and/or “us” refer to First
            Games and “you” and/or “your” refer to our customers.
          </p>
        </div>
      </Container>
    </>
  );
}
