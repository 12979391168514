import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import QRCode from "react-qr-code";
import axios from "axios";
import { db } from "../helpers/firebase";
import { get, ref, update } from "firebase/database";

export default function Payment() {
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [chessPaymentMessage, setChessPaymentMessage] = useState("");

  const {
    showChessPayment,
    setShowChessPayment,
    setShowChessPriceRadio,
    url,
    setUrl,
    setChessPaymentStatus,
  } = useAuth();

  useEffect(() => {
    let timer;
    if (qrIsVisible) {
      timer = setTimeout(() => {
        setQrIsVisible(false);
      }, 60000); // Hide the QR code after 60 seconds
    }
    return () => clearTimeout(timer); // Clear the timer when component unmounts or if qrIsVisible changes
  }, [qrIsVisible]);

  useEffect(() => {
    handleQrCodeGenerator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get("https://playnikunjstar.com:5001/api/transaction-status")
        .then(async (response) => {
          const status = response.data.status;
          const invoiceNumber = response.data.invoiceNumber; // Assuming invoice number is present in the response

          setChessPaymentMessage(status);
          if (status === "SUCCESS") {
            setChessPaymentStatus(true);
            try {
              const transactionRef = ref(db, `transactions/${invoiceNumber}`);
              const snapshot = await get(transactionRef);

              if (snapshot.exists()) {
                // Update the transaction status
                await update(transactionRef, {
                  status: "SUCCESS", // Update the status field
                });
                console.log("Transaction status successfully updated!");
              } else {
                console.error(
                  "No such transaction with the given invoice number!"
                );
              }
            } catch (error) {
              console.error("Error updating transaction status: ", error);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching transaction status:", error);
        });
    }, 3000); // Poll every 5 seconds

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQrCodeGenerator = () => {
    if (!url) {
      setErrorMessage("Please enter a URL.");
      return;
    }
    setErrorMessage(""); // Clear error message if URL is valid
    setQrIsVisible(true);
  };

  const handleBack = () => {
    setShowChessPayment(false);
    setShowChessPriceRadio(true); // Navigate back to the price selection step
    setUrl(""); // Clear the URL input
    setQrIsVisible(false); // Hide the QR code
    setErrorMessage(""); // Clear any error message
  };

  return (
    <>
      {showChessPayment && (
        <div className="absolute inset-0 flex items-center justify-center flex-col bg-gray-100 shadow-lg shadow-gray-800 rounded-lg z-50 w-[500px] left-auto right-auto p-8 min-h-[370px] h-full top-1/2 -translate-y-2/4">
          <h4 className="text-primary text-2xl font-Kanit font-medium leading-6 mb-6">
            QR Code Generator
          </h4>
          <form className="flex items-center justify-center flex-col">
            {errorMessage && (
              <div className="text-red-500 mb-4 text-sm">{errorMessage}</div>
            )}

            {qrIsVisible && (
              <div className="mt-4">
                <QRCode value={url} size={100} />
              </div>
            )}

            {chessPaymentMessage && (
              <div className="mt-4 text-green-500">
                Transaction Status: {chessPaymentMessage}
              </div>
            )}

            <div className="flex items-center justify-center gap-6 mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="text-white bg-gray-500 shadow-md shadow-black hover:shadow-black hover:bg-gray-700 hover:text-white focus:ring-4 focus:ring-gray-700 font-medium rounded-lg text-sm px-7 py-2.5 focus:outline-none"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
