import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function OurStory() {
  return (
    <>
      <BreadCrumb pageName="Refund Policy" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Refund Policy</h1>
          <p className="text-md mt-4 text-gray-600">
            Thank you for shopping at NikunjStar. We appreciate the fact that
            you like to buy the games we develop. We also want to make sure you
            have a rewarding experience while you're exploring, evaluating, and
            purchasing our games.
          </p>
          <p className="text-md mt-4 text-gray-600">
            As with any shopping experience, there are terms and conditions that
            apply to transactions at NikunjStar. We'll be as brief as our
            attorneys will allow. The main thing to remember is that by placing
            an order or making a purchase at NikunjStar, you agree to the terms
            set forth below along with Policy.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            General Conditions:
          </h2>
          <p className="text-md text-gray-600">
            All sales of digital content and virtual items are considered final
            and non-refundable, except as outlined below. Refunds are issued
            solely at the discretion of NikunjStar management.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Refund Eligibility:
          </h2>
          <ul className="list-disc pl-5">
            <li>
              <p>
                <span className="font-semibold pr-2">Faulty Product:</span>
                If the game is not functioning properly, is corrupted or
                significantly different from its description, you may request a
                refund within 14 days of purchase.
              </p>
            </li>
            <li>
              <p>
                <span className="font-semibold pr-2">Non-Delivery:</span>
                In case you do not receive the digital product link from us,
                despite having made the payment, you can claim a refund within
                14 days from the date of the supposed delivery.
              </p>
            </li>
            <li>
              <p>
                <span className="font-semibold pr-2">
                  Accidental Purchases:
                </span>
                Requests for refunds of accidental purchases must be made within
                24 hours of the transaction.
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Process:
          </h2>
          <p className="text-md text-gray-600">
            To request a refund, please follow these steps:
          </p>
          <ul className="list-disc pl-5">
            <li>
              <p>Contact our Customer Support team at [Contact Information].</p>
            </li>
            <li>
              <p>Provide your order number and the reason for your request.</p>
            </li>
            <li>
              <p>
                Await our team's review of your request, which typically takes
                2-3 business days.
              </p>
            </li>
            <li>
              <p>
                If approved, the refund will be processed, and a credit will
                automatically be applied to your credit card or original method
                of payment within certain amount of days.
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Exceptions:
          </h2>
          <p className="text-md text-gray-600">
            Note that we do not provide refunds for the following:
          </p>
          <ul className="list-disc pl-5">
            <li>
              <p>
                Minor bugs or glitches that do not affect the overall
                playability of the game.
              </p>
            </li>
            <li>
              <p>Dissatisfaction stemming from personal preference.</p>
            </li>
            <li>
              <p>
                Game purchases made through third-party platforms must be
                refunded through those platforms.
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
}
