import { useAuth } from "../context/AuthContext";
import { homeCardData } from "../utils/data/homeCardData";
import Container from "./Container";
import HowToPlay from "./HowToPlay";

export default function HomeCard() {
  const { setShowHowTo, setHowToID } = useAuth();

  return (
    <>
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-14 md:gap-10 px-12 md:px-20 lg:px-10 xl:px-28">
          {homeCardData.map((content) => (
            <button
              onClick={() => {
                setShowHowTo(true);
                setHowToID(content.id - 1);
              }}
              key={content.id}
              className="bg-gray-50 hover:bg-white border rounded-lg border-gray-300 shadow-lg hover:shadow-slate-300 hover:-translate-y-1 transition-transform p-4 space-y-4 "
            >
              <div className="flex items-center justify-center flex-col relative h-full">
                <img
                  src={content.img}
                  alt={content.label}
                  width={200}
                  height={200}
                  className="pb-12"
                />
                <h5 className="font-extrabold text-2xl md:text-xl text-slate-900 pt-4 absolute bottom-0">
                  {content.label}
                </h5>
              </div>
            </button>
          ))}
        </div>
        <HowToPlay />
      </Container>
    </>
  );
}
