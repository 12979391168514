import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";

export default function OurStory() {
  return (
    <>
      <BreadCrumb pageName="Our Story" />
      <Container>
        <div className="mb-8 space-y-4">
          <h1 className="text-3xl font-bold">Our Story</h1>
          <p className="text-md mt-4 text-gray-600">
            {/* <span className="font-semibold">
              Golden Legand Leasing & Finance Limited (GLLFL)
            </span>{" "} */}
            Welcome to Nikunjstarinfo, where every click leads to excitement!
            Founded in [Year], our journey began with a simple mission: to
            revolutionize the gaming industry by creating unforgettable
            experiences. Based in [City/Country], we’ve grown from a passionate
            team of game developers into a premier name in gaming, reaching
            players around the globe.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Our Vision
          </h2>
          <p className="text-md text-gray-600">
            Our vision is to become a leader in the gaming world, known for
            innovative gameplay mechanics and compelling storylines that engage
            players on a deeper level. We strive to create a gaming environment
            that is accessible to everyone, with games that are easy to learn
            but challenging to master.
          </p>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Our Commitment
          </h2>
          <ul className="list-disc pl-5">
            <li>
              <p>
                <span className="font-semibold pr-2">Innovation:</span>
                Constantly exploring new ideas and technologies to enhance our
                games.
              </p>
            </li>
            <li>
              <p>
                <span className="font-semibold pr-2">Quality:</span>
                Ensuring each game meets our high standards of graphics,
                performance, and user engagement.
              </p>
            </li>

            <li>
              <p>
                <span className="font-semibold pr-2">Community:</span>
                Building a vibrant community of gamers who share their
                experiences and grow together.
              </p>
            </li>
          </ul>
          <h2 className="text-xl font-bold underline underline-offset-4">
            Join Us on Our Journey
          </h2>
          <p className="text-md text-gray-600">
            Whether you’re a seasoned gamer or just starting out, we invite you
            to explore our world at DhamakaGames. Discover new adventures,
            conquer challenges, and become part of a community that celebrates
            the joy of gaming. Keep up with our latest updates and releases by
            following us on [Social Media Links] and visiting our website
            regularly.
          </p>
        </div>
      </Container>
    </>
  );
}
